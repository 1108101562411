import React, { useState } from 'react'
import { Button, Form, Dimmer, Loader } from 'semantic-ui-react'
import ApiAgent from './ApiAgent';
import { convertToHankakuAlphaNumeric } from './Utils'

function LoginForm({ defaultCode, onLogin, onError }) {
  const [code, setCode] = useState(defaultCode);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const login = () => {
    setLoading(true);
    ApiAgent(null).send(
      "POST", "/token", { code: convertToHankakuAlphaNumeric(code).trim(), password: password },
      (x) => {
        onLogin(x);
        setLoading(false);
      },
      (e) => {
        if (e.status === 401) {
          onError("ログインできませんでした。診察券番号・パスワードの入力に誤りがないかご確認ください。パスワードを忘れてしまった方は、当院までお電話ください。");
        } else {
          onError(e.message);
        }
        setLoading(false);
      }
    );
  };

  const checkInput = () => {
    return (code !== "" && password !== "");
  }

  return (
    <Form size='large' style={{ textAlign: "center" }}>
      <Dimmer active={loading} inverted><Loader inverted>通信中..</Loader></Dimmer>
      <Form.Input inline label='診察券番号' value={code} onChange={(e) => setCode(e.target.value)} />
      <Form.Input inline label='パスワード' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
      <p style={{ textAlign: "right" }}>初期パスワードは、あなたの誕生日です（例：5月3日 → 0503）</p>
      <div style={{ textAlign: "center", margin: "1em 0" }} >
        <Button color='teal' size='large' onClick={login} disabled={!checkInput()}>ログイン</Button>
      </div>
    </Form>
  );
}

export default LoginForm